<template>
  <nav class="theme-vanderbuild sticky top-0 bg-white border-b z-30">
    <div
      class="p-5 md:p-10 flex flex-row-reverse lg:flex-row justify-between max-w-screen-fhd mx-auto"
    >
      <router-link :to="{ name: 'Dashboard' }">
        <img
          src="@/assets/logo_dewaelevastgoedgroep.png"
          alt="Dewaele Vastgoed Groep"
          class="h-10 lg:h-16 w-auto"
        />
      </router-link>
      <div
        class="h-auto w-auto my-auto hidden lg:flex flex-wrap justify-end text-gray-cc space-x-4"
      >
        <component
          :is="link.to ? 'router-link' : 'a'"
          v-for="(link, index) in links"
          :key="`desktop_nav_item_${index}`"
          :to="link.to"
          exact
          active-class="text-tg-light-text border-b border-tg-light-text"
          class="tg-desktop-header-5 border-tg-light-text hover:border-b hover:text-tg-light-text cursor-pointer"
          @click="$emit(link.action)"
        >
          {{ link.name }}
        </component>
      </div>
      <div class="block lg:hidden h-auto my-auto">
        <i
          class="fal text-3xl leading-normal transform rotate-180 cursor-pointer"
          :class="sideMenu ? 'fa-times text-white' : 'fa-stream'"
          @click="toggleSideMenu"
        />
        <transition name="fade" mode="out-in">
          <div
            v-show="sideMenu"
            class="fixed top-0 left-0 flex h-full w-full z-40 cursor-pointer bg-overlay"
            @click="toggleSideMenu"
          >
            <aside
              class="bg-brand-primary bg-opacity-100 h-full w-4/5 max-w-sm overflow-auto"
            >
              <component
                :is="link.to ? 'router-link' : 'a'"
                v-for="(link, index) in links"
                :key="`mobile_nav_item_${index}`"
                :to="link.to"
                exact
                active-class="tg-mobile-body-bold text-opacity-100"
                class="block w-full p-7.5 tg-mobile-body text-white text-opacity-40 border-b border-white border-opacity-40 cursor-pointer"
                @click="$emit(link.action)"
              >
                <i :class="['mr-2 fal', link.icon]" /> {{ link.name }}
              </component>
            </aside>
          </div>
        </transition>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      sideMenu: false
    };
  },
  computed: {
    links() {
      // Links are computed because data properties don't update
      // on locale change since the nested keys aren't being watched
      return [
        {
          name: 'Home',
          icon: 'fa-tachometer',
          to: { name: 'Dashboard' }
        },
        // {
        //   name: this.$t('houses'),
        //   icon: 'fa-home',
        //   to: { name: 'Houses' }
        // },
        {
          name: this.$t('my_profile'),
          icon: 'fa-user',
          to: { name: 'Profile' }
        },
        // {
        //   name: this.$t('my_newsletters'),
        //   icon: 'fa-bullhorn',
        //   to: { name: 'Newsletters' }
        // },
        // {
        //   name: this.$t('my_searches'),
        //   icon: 'fa-search',
        //   to: { name: 'Searches' }
        // },
        // {
        //   name: this.$t('need_help'),
        //   icon: 'fa-life-ring',
        //   to: { name: 'Help' }
        // },
        {
          name: this.$t('log_out'),
          icon: 'fa-sign-out',
          action: 'logout'
        }
      ];
    }
  },
  methods: {
    hideSideMenu() {
      this.sideMenu = false;
    },
    showSideMenu() {
      this.sideMenu = true;
    },
    toggleSideMenu() {
      if (this.sideMenu) {
        this.hideSideMenu();
      } else {
        this.showSideMenu();
      }
    }
  }
};
</script>
