<template>
  <div class="theme-dewaele">
    <Navbar @logout="authLogout" />
    <transition name="fade" mode="out-in">
      <router-view
        :key="$route.fullPath"
        class="p-5 md:p-10 max-w-screen-fhd mx-auto"
      />
    </transition>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import { mapActions } from 'vuex';

export default {
  name: 'DashboardLayout',
  components: { Navbar },
  methods: {
    ...mapActions('auth', ['logout']),
    authLogout() {
      this.logout().then(() => {
        this.$store.dispatch('snackbar/show', {
          type: 'success',
          message: this.$t('you_have_been_logged_out'),
          route: { name: 'AuthLogin' }
        });
      });
    }
  }
};
</script>
